<template>
  <div class="container">
    <img class="top-img" :src="`${$link}/h5img/home01.png`" alt="" />
    <mobile-title
      title="全品类权益一站式供应"
      tip="多家优质品牌入驻｜多场景服务｜支持API对接"
    />
    <div class="category-box">
      <img :src="`${$link}/h5img/home02.png`" alt="" srcset="" />
    </div>

    <mobile-title
      title="爆款营销"
      tip="支持企业个性化营销服务，按需定制，驱动企业实现业绩增长"
    />

    <div class="blockbusterMarketing-box">
      <div class="blockbusterMarketing">
        <div class="block-opt">
          <img :src="`${$link}/h5img/home03.png`" alt="" />
          <div class="block-title">拉新促活</div>
          <div class="block-tip">快速拉高运营效果</div>
        </div>
        <div class="block-opt">
          <img :src="`${$link}/h5img/home04.png`" alt="" />
          <div class="block-title">营销触达</div>
          <div class="block-tip">消息、短信发奖、快速触达</div>
        </div>
        <div class="block-opt">
          <img :src="`${$link}/h5img/home05.png`" alt="" />
          <div class="block-title">企业福利</div>
          <div class="block-tip">驱动实现业绩增长</div>
        </div>
        <div class="block-opt">
          <img :src="`${$link}/h5img/home06.png`" alt="" />
          <div class="block-title">增长裂变</div>
          <div class="block-tip">行业杀手级裂变工具</div>
        </div>
      </div>
    </div>

    <mobile-form />

    <mobile-title title="供货方式" tip="支持多种供货方式，多端触达，一步到位" />
    <mobile-mode-supply />

    <mobile-title
      title="核心优势"
      tip="[工具+服务+营销]一步到位，一站式解决方案"
    />
    <mobile-advantage />

    <mobile-title
      title="匠心产品 用心服务"
      tip="金牌行业专家1V1服务 400-658-0368"
      call="1"
    />
    <div class="serve-box">
      签订合同后成立专属项目服务对接群<br />
      当日~T+1内按需发货<br />
      7*24小时家服及技术运维支撑<br />
      可开立6%增值税专用发票，可抵销项税<br />
      增值税别:信息技术服务费/现代服务费<br />
      <div class="serve-content">我们全力为客户保障高质量的服务及用户体验</div>
    </div>

    <mobile-title
      title="合作案例"
      tip="权益解决方案涵盖银行、保险、通信、互联网等多个领域"
    />
    <mobile-coll-cases />

    <mobile-form />

    <div class="contact-box" @click="callPhone('400-658-0368')">联系我们</div>
    <div class="address">深圳赛迪文信息科技有限公司</div>
    <div class="address">总部地址：深圳市福田区景田西路17号赛格景苑A栋2008</div>
    <div class="address">
      分公司地址：南宁良庆区广西合景国际金融广场31楼3106
    </div>

    <!-- 弹框 -->
    <transition name="el-zoom-in-top">
      <div class="pop-frame" @touchmove.prevent v-if="showPop">
        <i class="el-icon-close close-icon" @click="showPop = false"></i>
        <mobile-form />
      </div>
    </transition>
  </div>
</template>

<script>
import MobileForm from '@/components/mobileForm.vue'
import MobileTitle from '@/components/mobileTitle.vue'
import MobileModeSupply from '@/components/mobileModeSupply.vue'
import MobileAdvantage from '@/components/mobileAdvantage.vue'
import MobileCollCases from '@/components/mobileCollCases.vue'
export default {
  name: 'mobileHome',
  data () {
    return {
      showPop: false
    }
  },
  components: {
    MobileForm,
    MobileTitle,
    MobileModeSupply,
    MobileAdvantage,
    MobileCollCases
  },
  methods: {
    // 调用拨号功能
    callPhone (phoneNumber) {
      window.location.href = 'tel://' + phoneNumber
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-bottom: 0.6rem;
  background: #0f62fe;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .category-box {
    background: #fff;
    margin: 0 0.36rem;
    border-radius: 0.4rem;
    padding: 0.34rem 0.36rem 0.2rem 0.12rem;
  }
  .blockbusterMarketing-box {
    margin: 0 0.36rem 0.4rem;
    .blockbusterMarketing {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      color: #fff;
      .block-opt {
        width: 50%;
        img {
          width: 1.78rem;
          height: 1.78rem;
          margin: 0 auto;
        }
        .block-title {
          font-size: 0.28rem;
          line-height: 0.4rem;
          letter-spacing: 1px;
          margin: 0.1rem 0;
        }
        .block-tip {
          font-size: 0.24rem;
          line-height: 0.34rem;
          letter-spacing: 1px;
          margin-bottom: 0.4rem;
        }
      }
    }
  }
  .serve-box {
    color: #333333;
    font-size: 0.28rem;
    line-height: 0.52rem;
    background: #fff;
    border-radius: 0.2rem;
    padding: 0.3rem 0 0.4rem;
    margin: 0 0.36rem;
    .serve-content {
      color: #fe6226;
      border-radius: 0.1rem;
      border: 1px dashed #fe6226;
      display: inline-block;
      padding: 0 0.2rem;
      margin-top: 0.3rem;
      box-sizing: border-box;
    }
  }
  .contact-box {
    height: 1rem;
    margin: 0.6rem 0.36rem;
    font-size: 0.32rem;
    color: #ffffff;
    line-height: 1rem;
    background: url('@{link}/h5img/home18.png') no-repeat;
    background-size: 100% 100%;
  }
  .address {
    color: #ffffff;
    font-size: 0.24rem;
    line-height: 0.34rem;
    margin-top: 0.1rem;
  }
  .pop-frame {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    .close-icon {
      color: #ffffff;
      font-size: 0.65rem;
      position: fixed;
      top: 0.35rem;
      right: 0.3rem;
    }
  }
}
</style>
