<template>
  <div class="about">
    <div class="about_banner">
      <img :src="`${$link}/webimg/about_head_bgm.png`" alt="" />
    </div>
    <div class="item_about_box">
      <span>关于我们</span>
      <div class="item_about_details">
        <div class="item_about_details_let">
          <img :src="`${$link}/webimg/about_item_details.png`" alt="" />
        </div>
        <div class="item_about_details_rig">
          <p>
            深圳赛迪文信息技术有限公司（以下简称赛迪文）是数字权益营销服务供应商，以“科技赋能
            定制未来”为核心理念，专注于为行业提供一站式B2B和B2B2C营销解决方案。凭借多年技术积累和行业经验，赛迪文整合丰富的权益资源。
            赛迪文在多家银行提供高质量的营销服务，并自主研发了权益解决方案平台——“悦动权益”。通过互联网+精准营销理念，以积分和电子凭证为载体，通过金融权益、积分运营、营销支撑服务的商业模式，聚焦银行、保险、金融、传媒、互联网、通信、政企、民企等大型行业机构，集合生活服务类优质品牌资源，提供上下游一体的综合权益解决方案，提升用户体验，创造社会价值。构建“跨地域、跨品牌、多元化、融合性”的以“产业互联、创新融合”
            的多方共赢的生态模式。
          </p>
        </div>
      </div>
      <span>荣誉资质</span>
      <div class="item_honor_box">
        <div class="item_honor_box_left_topImg">
          <img :src="`${$link}/webimg/certificate_1.png`" alt="" />

          <p>政府采购优秀供应商</p>
        </div>
        <div class="item_honor_box_rg_topImg">
          <img :src="`${$link}/webimg/cmmi.png`" alt="" />

          <p>CMMI证书</p>
        </div>
        <div class="item_honor_box_i">
          <img :src="`${$link}/webimg/certificate_2.png`" alt="" />
          <p>ICP增值电信经营许可证</p>
        </div>
        <div class="item_honor_box_i">
          <img :src="`${$link}/webimg/certificate_3.png`" alt="" />
          <p>SP增值电信经营许可证</p>
        </div>
        <div class="item_honor_box_i">
          <img :src="`${$link}/webimg/certificate_4.png`" alt="" />
          <p>中国国家顶级域名证书</p>
        </div>
        <div class="item_honor_box_i">
          <img :src="`${$link}/webimg/certificate_6.png`" alt="" />
          <p>信息技术服务管理体系认证证书</p>
        </div>
        <div class="item_honor_box_i">
          <img :src="`${$link}/webimg/certificate_5.png`" alt="" />
          <p>信息安全管理体系认证证书</p>
        </div>
        <div class="item_honor_box_i">
          <img :src="`${$link}/webimg/certificate_9.png`" alt="" />
          <p>环境管理体系认证证书</p>
        </div>
        <div class="item_honor_box_i">
          <img :src="`${$link}/webimg/certificate_7.png`" alt="" />
          <p>能源管理体系认证证书</p>
        </div>
        <div class="item_honor_box_i">
          <img :src="`${$link}/webimg/certificate_8.png`" alt="" />
          <p>网络文化经营许可证</p>
        </div>
      </div>
      <!-- <span style="background-color: #f8fafc; font-size: 40px">联系我们</span> -->
    </div>
    <div class="contact_box">
      <div class="map_box">
        <span class="map_box_title">联系我们</span>
        <div class="address_box">
          <div class="address_box_item">
            <img :src="`${$link}/webimg/address_1.png`" alt="" />
            <div class="phone_box">
              <span> 联系地址 </span>
              <p>南宁良庆区广西合景国际金融广场31楼3106</p>
            </div>
          </div>
          <div class="address_box_item">
            <img :src="`${$link}/webimg/address_2.png`" alt="" />
            <div class="phone_box">
              <span> 商务合作 </span>
              <p>18511091820（金经理）</p>
            </div>
          </div>
          <div class="address_box_item">
            <img :src="`${$link}/webimg/address_3.png`" alt="" />
            <div class="phone_box">
              <span> 客服电话（7*24小时） </span>
              <p>400-658-0368</p>
            </div>
          </div>
        </div>
        <div class="map_bg">
          <div>
            <img :src="`${$link}/webimg/address_01.png`" alt="" />
            <p>
              深圳地址：深圳市福田区莲花街道康欣社区景田西路17号赛格景苑A、B栋A栋2008
            </p>
          </div>
          <div>
            <img :src="`${$link}/webimg/address_02.png`" alt="" />
            <p>南宁地址：南宁良庆区广西合景国际金融广场31楼3106</p>
          </div>
        </div>
      </div>
    </div>
    <toTop />
  </div>
</template>
<script>
export default {
  name: 'mobilIndex'
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  position: relative;
}
.about {
  width: 100%;

  .about_banner {
    width: 100%;
    height: 300px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .item_about_box {
    width: 1200px;
    // background-color: skyblue;
    margin: 0 auto;

    span {
      font-weight: 700;
      font-size: 30px;
      color: #333333;
      position: relative;
      display: inline-block;
      margin-top: 100px;
      margin-bottom: 84px;
      &::after {
        content: '';
        display: block;
        width: 86px;
        height: 4px;
        background: #00a0fb;
        border-radius: 2px;
        position: absolute;
        top: 62px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .item_about_details {
      width: 100%;
      height: 375px;
      margin-bottom: 20px;
      position: relative;
      .item_about_details_let {
        width: 565px;
        height: 375px;
        position: absolute;
        top: 0;
        left: 0;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .item_about_details_rig {
        width: 744px;
        height: 335px;
        background: #ffffff;
        box-shadow: 0 0 10px 0 #0000001a;
        border-radius: 10px;
        position: absolute;
        top: 50%;
        right: 0;

        transform: translateY(-50%);
        p {
          padding: 25px 20px 31px 20px;
          box-sizing: border-box;
          text-align: left;
          width: 744px;
          height: 335px;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
          letter-spacing: 0.64px;
          text-align: justify;
          line-height: 31px;
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 744px;
            height: 10px;
            background-image: url('@{link}/webimg/about_details_border.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
    .item_honor_box {
      width: 1200px;
      margin: 0 auto;
      //   background: skyblue;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 100px;
      position: relative;
      padding-top: 300px;
      .item_honor_box_left_topImg {
        left: 49%;
        transform: translateX(-100%);
      }
      .item_honor_box_rg_topImg {
        right: 49%;
        transform: translateX(100%);
      }
      .item_honor_box_left_topImg,
      .item_honor_box_rg_topImg {
        width: 286px;
        height: 300px;
        position: absolute;
        top: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          display: block;
          width: 286px;
          height: 210px;
        }
        p {
          width: 126px;
          height: 20px;

          font-weight: 500;
          font-size: 14px;
          color: #555555;
          letter-spacing: 0;
          margin-top: 20px;
        }
      }
      .item_honor_box_i {
        // background-color: yellow;
        margin-bottom: 60px;
        img {
          display: block;
          width: 286px;
          height: 302px;
        }
        p {
          font-weight: 500;
          font-size: 14px;
          color: #555555;
          margin-top: 20px;
        }
      }
    }
  }
  .contact_box {
    width: 100%;
    // height: 776px;
    background-color: #f8fafc;

    .map_box {
      width: 1200px;
      margin: 0 auto;
      .map_box_title {
        font-weight: 700;
        font-size: 30px;
        color: #333333;
        display: inline-block;
        width: 127px;
        height: 42px;
        margin-top: 100px;
        margin-bottom: 84px;
        position: relative;
        &::after {
          content: '';
          display: block;
          width: 86px;
          height: 4px;
          background: #00a0fb;
          border-radius: 2px;
          position: absolute;
          top: 62px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .address_box {
        width: 100%;
        height: 70px;

        display: flex;
        justify-content: space-between;
        .address_box_item {
          display: flex;
          text-align: left;

          img {
            display: inline-block;
            width: 70px;
            height: 70px;
            margin-right: 15px;
          }
          .phone_box {
            padding: 5px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            p {
              font-weight: 500;
              font-size: 16px;
              color: #555555;
            }
            span {
              font-weight: 500;
              font-size: 20px;
              color: #555555;
            }
          }
        }
      }
    }
    .map_bg {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      padding-bottom: 100px;
      img {
        width: 580px;
        height: 300px;
        display: block;
        margin-bottom: 20px;
      }
      p {
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        color: #555555;
      }
    }
  }
}
</style>
