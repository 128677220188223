<template>
  <div class="about">
    <img
      class="about-top"
      :src="`${$link}/h5img/about01.png`"
      alt=""
      srcset=""
    />

    <div class="title-box">
      <div class="title">关于我们</div>
      <div class="line"></div>
    </div>

    <img
      class="office-environment"
      :src="`${$link}/h5img/about02.png`"
      alt=""
    />

    <div class="company-profile">
      深圳赛迪文信息技术有限公司（以下简称赛迪文）是数字权益营销服务供应商，以“科技赋能
      定制未来”为核心理念，专注于为行业提供一站式B2B和B2B2C营销解决方案。凭借多年技术积累和行业经验，赛迪文整合丰富的权益资源。
      赛迪文在多家银行提供高质量的营销服务，并自主研发了权益解决方案平台——“悦动权益”。通过互联网+精准营销理念，以积分和电子凭证为载体，通过金融权益、积分运营、营销支撑服务的商业模式，聚焦银行、保险、金融、传媒、互联网、通信、政企、民企等大型行业机构，集合生活服务类优质品牌资源，提供上下游一体的综合权益解决方案，提升用户体验，创造社会价值。构建“跨地域、跨品牌、多元化、融合性”的以“产业互联、创新融合”
      的多方共赢的生态模式。
    </div>

    <div class="title-box">
      <div class="title">荣誉资质</div>
      <div class="line"></div>
    </div>
    <div class="documents">
      <div class="document-item" v-for="item in documents" :key="item.id">
        <img class="document-item-img" :src="item.img" alt="" />
        <div class="document-item-title">{{ item.title }}</div>
      </div>
    </div>

    <div class="title-box">
      <div class="title">联系我们</div>
      <div class="line"></div>
    </div>
    <div class="contact-box">
      <div class="contact-item" v-for="item in contactList" :key="item.id">
        <img class="contact-item-img" :src="item.img" alt="" />
        <div class="contact-item-title">{{ item.title }}</div>
        <div class="contact-item-name">{{ item.headquarters }}</div>
        <div class="contact-item-name">{{ item.firm }}</div>
      </div>
    </div>

    <div class="address">
      <img :src="`${$link}/h5img/about08.png`" alt="" />
      <div class="address-title">
        总部：深圳市福田区景田西路17号赛格景苑A栋2008
      </div>
    </div>
    <div class="address">
      <img :src="`${$link}/h5img/about09.png`" alt="" />
      <div class="address-title">
        分公司：南宁良庆区广西合景国际金融广场31楼3106
      </div>
    </div>

    <mobile-foot class="foot" />
  </div>
</template>

<script>
import MobileFoot from '@/components/mobileFoot.vue'
export default {
  name: 'aboutUs',
  data () {
    return {
      documents: [
        {
          id: 1,
          img: this.$link + '/h5img/about05.png',
          title: '政府采购优秀供应商'
        },
        {
          id: 2,
          img: this.$link + '/h5img/cmmi.png',
          title: 'CMMI证书'
        },
        {
          id: 3,
          img: this.$link + '/h5img/about06.png',
          title: 'ICP增值电信业务经营许可证'
        },
        {
          id: 4,
          img: this.$link + '/h5img/about07.png',
          title: 'SP电信增值业务经营许可证'
        }
      ],
      contactList: [
        {
          id: 1,
          img: this.$link + '/h5img/about10.png',
          title: '联系地址',
          headquarters: '总部：深圳市福田区莲花街道景田西路17号赛格景苑A栋2008',
          firm: '分公司：南宁良庆区广西合景国际金融广场31楼3106'
        },
        {
          id: 2,
          img: this.$link + '/h5img/about11.png',
          title: '商务合作',
          headquarters: '18511091820(金经理)'
        },
        {
          id: 3,
          img: this.$link + '/h5img/about12.png',
          title: '客服电话(7*24小时)',
          headquarters: '400-658-0368'
        }
      ]
    }
  },
  components: { MobileFoot }
}
</script>

<style lang="less" scoped>
.about {
  background: #ffffff;
  .about-top {
    width: 100%;
    display: block;
  }
  .title-box {
    margin-top: 0.4rem;
    .title {
      color: #333333;
      font-size: 0.32rem;
      line-height: 0.44rem;
    }
    .line {
      height: 0.04rem;
      background: #0f62fe;
      border-radius: 0.04rem;
      margin: 0.2rem auto 0;
      width: 0.8rem;
    }
  }
  .office-environment {
    width: calc(100% - 0.72rem);
    display: block;
    margin: 0.3rem auto 0.2rem;
    border-radius: 0.2rem;
    box-shadow: 0 0 20px 0 #0000001a;
    overflow: hidden;
  }
  .company-profile {
    color: #333333;
    font-size: 0.28rem;
    margin: 0 0.36rem 1rem;
    background: #ffffff;
    box-shadow: 0 0 0.2rem 0 #0000001a;
    border-radius: 0.2rem;
    background-image: url('@{link}/h5img/about03.png');
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 100% 0.2rem;
    padding: 0.36rem 0.3rem 0.6rem;
    text-align: left;
    line-height: 0.52rem;
  }
  .documents {
    margin: 0 0.8rem 1rem;
    .document-item {
      margin-top: 0.6rem;
      .document-item-img {
        width: 100%;
        display: block;
      }
      .document-item-title {
        color: #555555;
        font-size: 0.28rem;
        margin-top: 0.2rem;
      }
    }
  }
  .contact-box {
    .contact-item {
      padding: 0.3rem 0;
      .contact-item-img {
        width: 0.92rem;
        height: 0.92rem;
        display: block;
        margin: 0 auto;
      }
      .contact-item-title {
        color: #333333;
        font-size: 0.28rem;
        margin-top: 0.2rem;
      }
      .contact-item-name {
        color: #555555;
        font-size: 0.24rem;
        margin-top: 0.16rem;
      }
    }
  }
  .address {
    margin: 0.4rem 0.36rem 0;
    img {
      width: 100%;
      display: block;
      border-radius: 0.2rem;
    }
    .address-title {
      color: #555555;
      font-size: 0.28rem;
      margin-top: 0.2rem;
    }
  }
  .foot {
    margin-top: 0.6rem;
  }
}
</style>
